
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _403WRigwub4siMeta } from "/opt/build/repo/apps/back-office/pages/403.vue?macro=true";
import { default as callbackgm73ofIZLoMeta } from "/opt/build/repo/apps/back-office/pages/auth/callback.vue?macro=true";
import { default as confirmationAEp04yAiGJMeta } from "/opt/build/repo/apps/back-office/pages/auth/confirmation.vue?macro=true";
import { default as defaultPmi4ZoxMbZMeta } from "/opt/build/repo/apps/back-office/pages/auth/default.vue?macro=true";
import { default as recoveryN42mgWufHsMeta } from "/opt/build/repo/apps/back-office/pages/auth/recovery.vue?macro=true";
import { default as signatureCUH55DpU7DMeta } from "/opt/build/repo/apps/back-office/pages/customer/timesheets/signature.vue?macro=true";
import { default as _91id_93RYQhXUrAZYMeta } from "/opt/build/repo/apps/back-office/pages/index/index/advance-payment-requests/index/[id].vue?macro=true";
import { default as list2yBdNu5wX4Meta } from "/opt/build/repo/apps/back-office/pages/index/index/advance-payment-requests/index/list.vue?macro=true";
import { default as indexR5s8NauPU2Meta } from "/opt/build/repo/apps/back-office/pages/index/index/advance-payment-requests/index.vue?macro=true";
import { default as _91id_93mHzIpuYSCMMeta } from "/opt/build/repo/apps/back-office/pages/index/index/amendments/[id].vue?macro=true";
import { default as indexoCu9iUocJjMeta } from "/opt/build/repo/apps/back-office/pages/index/index/amendments/index.vue?macro=true";
import { default as indexrn4gtN6IBjMeta } from "/opt/build/repo/apps/back-office/pages/index/index/applications/index.vue?macro=true";
import { default as indexkVTGHDEyQeMeta } from "/opt/build/repo/apps/back-office/pages/index/index/calculator/index.vue?macro=true";
import { default as indexIX7NMXY2RZMeta } from "/opt/build/repo/apps/back-office/pages/index/index/calculators/index.vue?macro=true";
import { default as indexuFwX1TuGkZMeta } from "/opt/build/repo/apps/back-office/pages/index/index/commercial-conditions/index.vue?macro=true";
import { default as _91id_93uycz1UtP5KMeta } from "/opt/build/repo/apps/back-office/pages/index/index/companies/[id].vue?macro=true";
import { default as _91id_93R97wBboYppMeta } from "/opt/build/repo/apps/back-office/pages/index/index/company-groups/[id].vue?macro=true";
import { default as indexqwnNgwACRJMeta } from "/opt/build/repo/apps/back-office/pages/index/index/company-insurance-requests/index.vue?macro=true";
import { default as _91id_93JkSq8Uc2zKMeta } from "/opt/build/repo/apps/back-office/pages/index/index/contacts/[id].vue?macro=true";
import { default as indexD3JWPOqHXAMeta } from "/opt/build/repo/apps/back-office/pages/index/index/contacts/index.vue?macro=true";
import { default as _91id_936wJrEYxEv5Meta } from "/opt/build/repo/apps/back-office/pages/index/index/contracts/[id].vue?macro=true";
import { default as indexfPpWb4rPQhMeta } from "/opt/build/repo/apps/back-office/pages/index/index/contracts/index.vue?macro=true";
import { default as indexqEfG9I9LKDMeta } from "/opt/build/repo/apps/back-office/pages/index/index/convert/index.vue?macro=true";
import { default as indexWMKuo9YBcnMeta } from "/opt/build/repo/apps/back-office/pages/index/index/cooptation/index.vue?macro=true";
import { default as action_45centerSd1zCpqW5BMeta } from "/opt/build/repo/apps/back-office/pages/index/index/dashboard/index/action-center.vue?macro=true";
import { default as statisticIjcZUZU4nKMeta } from "/opt/build/repo/apps/back-office/pages/index/index/dashboard/index/statistic.vue?macro=true";
import { default as indexkLyeEeH6RlMeta } from "/opt/build/repo/apps/back-office/pages/index/index/dashboard/index.vue?macro=true";
import { default as indexlpfi9GbbLSMeta } from "/opt/build/repo/apps/back-office/pages/index/index/definitive-end-requests/index.vue?macro=true";
import { default as _91id_93acxKibX6SIMeta } from "/opt/build/repo/apps/back-office/pages/index/index/expense-claim-requests/[id].vue?macro=true";
import { default as indexX3Ui7moMwdMeta } from "/opt/build/repo/apps/back-office/pages/index/index/expense-claim-requests/index.vue?macro=true";
import { default as _91id_93H0d3ahY9EXMeta } from "/opt/build/repo/apps/back-office/pages/index/index/independent-payment-requests/[id].vue?macro=true";
import { default as indexczXvRnFkdVMeta } from "/opt/build/repo/apps/back-office/pages/index/index/independent-payment-requests/index.vue?macro=true";
import { default as indexorE9GequR8Meta } from "/opt/build/repo/apps/back-office/pages/index/index/index.vue?macro=true";
import { default as indexjmcchOnwmFMeta } from "/opt/build/repo/apps/back-office/pages/index/index/invoice-simulator/index.vue?macro=true";
import { default as indexhLIePa8OWKMeta } from "/opt/build/repo/apps/back-office/pages/index/index/meal-card-requests/index.vue?macro=true";
import { default as indexFDFFtvz9qOMeta } from "/opt/build/repo/apps/back-office/pages/index/index/medical-centers/index.vue?macro=true";
import { default as _91id_93ZPROX1vyHoMeta } from "/opt/build/repo/apps/back-office/pages/index/index/medical-checkup-requests/[id].vue?macro=true";
import { default as indexW6QLPIaqigMeta } from "/opt/build/repo/apps/back-office/pages/index/index/medical-checkup-requests/index.vue?macro=true";
import { default as applicationsiKaiLyTcC1Meta } from "/opt/build/repo/apps/back-office/pages/index/index/missions/[id]/applications.vue?macro=true";
import { default as detailsXyLKmoLUdlMeta } from "/opt/build/repo/apps/back-office/pages/index/index/missions/[id]/details.vue?macro=true";
import { default as find_45talentsmtJRmdaunvMeta } from "/opt/build/repo/apps/back-office/pages/index/index/missions/[id]/find-talents.vue?macro=true";
import { default as job_45postingeQYZp54rDwMeta } from "/opt/build/repo/apps/back-office/pages/index/index/missions/[id]/job-posting.vue?macro=true";
import { default as notesyTgVgdym7fMeta } from "/opt/build/repo/apps/back-office/pages/index/index/missions/[id]/notes.vue?macro=true";
import { default as _91id_93MizrbcFo3jMeta } from "/opt/build/repo/apps/back-office/pages/index/index/missions/[id].vue?macro=true";
import { default as client_45orderRjpPK7StGXMeta } from "/opt/build/repo/apps/back-office/pages/index/index/missions/client-order.vue?macro=true";
import { default as indexFd4hBMYSgqMeta } from "/opt/build/repo/apps/back-office/pages/index/index/missions/index.vue?macro=true";
import { default as talent_45pooling6U6qZJhX3eMeta } from "/opt/build/repo/apps/back-office/pages/index/index/missions/talent-pooling.vue?macro=true";
import { default as indexNbgUd4GKS6Meta } from "/opt/build/repo/apps/back-office/pages/index/index/organigram/index.vue?macro=true";
import { default as _91id_93LE32o1hEiZMeta } from "/opt/build/repo/apps/back-office/pages/index/index/pay-tracking/[id].vue?macro=true";
import { default as indexEuItLYDCOyMeta } from "/opt/build/repo/apps/back-office/pages/index/index/pay-tracking/index.vue?macro=true";
import { default as _91contract_key_934AUM5stZ5fMeta } from "/opt/build/repo/apps/back-office/pages/index/index/planning/[contract_key].vue?macro=true";
import { default as indexc33VjYYY5OMeta } from "/opt/build/repo/apps/back-office/pages/index/index/planning/index.vue?macro=true";
import { default as indextiFAIFyIgUMeta } from "/opt/build/repo/apps/back-office/pages/index/index/ppe-requests/index.vue?macro=true";
import { default as _91id_93G7m4bRpJCUMeta } from "/opt/build/repo/apps/back-office/pages/index/index/professional-card-requests/[id].vue?macro=true";
import { default as indexPBAMhu8W4gMeta } from "/opt/build/repo/apps/back-office/pages/index/index/professional-card-requests/index.vue?macro=true";
import { default as _91id_93Q9o0GNkg1FMeta } from "/opt/build/repo/apps/back-office/pages/index/index/qualification-centers/[id].vue?macro=true";
import { default as index90BnVxBF7GMeta } from "/opt/build/repo/apps/back-office/pages/index/index/qualification-centers/index.vue?macro=true";
import { default as _91id_93uP7PAKhBj0Meta } from "/opt/build/repo/apps/back-office/pages/index/index/qualification-training-requests/[id].vue?macro=true";
import { default as indexOElryxBK1EMeta } from "/opt/build/repo/apps/back-office/pages/index/index/qualification-training-requests/index.vue?macro=true";
import { default as _91id_933Kvz2fqrUVMeta } from "/opt/build/repo/apps/back-office/pages/index/index/recruitment-office-invoice-requests/[id].vue?macro=true";
import { default as indexVNU9cYjE90Meta } from "/opt/build/repo/apps/back-office/pages/index/index/recruitment-office-invoice-requests/index.vue?macro=true";
import { default as indexWOJ6vDJuwmMeta } from "/opt/build/repo/apps/back-office/pages/index/index/reference-checks/index.vue?macro=true";
import { default as anonymous_45bioSzvhr8cU6aMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/anonymous-bio.vue?macro=true";
import { default as anonymous_45resumemiqqRDlfqAMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/anonymous-resume.vue?macro=true";
import { default as applicationsivFMbJDjnCMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/applications.vue?macro=true";
import { default as contractsQGncl9Bg9RMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/contracts.vue?macro=true";
import { default as detailsBpx7FurTFPMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/details.vue?macro=true";
import { default as documentsFwfHiZz4sYMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/documents.vue?macro=true";
import { default as find_45missionsIUtfpLTskzMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/find-missions.vue?macro=true";
import { default as new_45pageUbS6TQzhokMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/new-page.vue?macro=true";
import { default as notesJHoKOParKUMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/notes.vue?macro=true";
import { default as qualificationFsbJSxwMPWMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/qualification.vue?macro=true";
import { default as resumeqpGZcmKKadMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/resume.vue?macro=true";
import { default as _91id_93PmmYiAnQC4Meta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id].vue?macro=true";
import { default as detailsi6EDvYMFK3Meta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/hiring-pools/[id]/details.vue?macro=true";
import { default as indexfSdvNiMSK3Meta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/hiring-pools/index.vue?macro=true";
import { default as listG7Wa3iBIHDMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/list.vue?macro=true";
import { default as push_45marketing6ZAG4ImgJiMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/push-marketing.vue?macro=true";
import { default as substitute_45benchnDcAsoblthMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/substitute-bench.vue?macro=true";
import { default as super_45searchXbfHt31kRmMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index/super-search.vue?macro=true";
import { default as indexlPC2C1GSBmMeta } from "/opt/build/repo/apps/back-office/pages/index/index/talents/index.vue?macro=true";
import { default as indexyl9Usi18d1Meta } from "/opt/build/repo/apps/back-office/pages/index/index/team-members/index.vue?macro=true";
import { default as indexpSVOOP493xMeta } from "/opt/build/repo/apps/back-office/pages/index/index/timesheets/index.vue?macro=true";
import { default as indexiEEXUv1E2uMeta } from "/opt/build/repo/apps/back-office/pages/index/index/users/index.vue?macro=true";
import { default as _91id_93HQlZd5QHDnMeta } from "/opt/build/repo/apps/back-office/pages/index/index/work-accident-requests/[id].vue?macro=true";
import { default as indexMR8NJrgjfjMeta } from "/opt/build/repo/apps/back-office/pages/index/index/work-accident-requests/index.vue?macro=true";
import { default as indexDjBfQXnN9UMeta } from "/opt/build/repo/apps/back-office/pages/index/index.vue?macro=true";
import { default as account968SQs7fJ6Meta } from "/opt/build/repo/apps/back-office/pages/index/settings/index/account.vue?macro=true";
import { default as membersWgoyzjArYfMeta } from "/opt/build/repo/apps/back-office/pages/index/settings/index/members.vue?macro=true";
import { default as workspacer5dASG9OJvMeta } from "/opt/build/repo/apps/back-office/pages/index/settings/index/workspace.vue?macro=true";
import { default as index5b7i6UHe7aMeta } from "/opt/build/repo/apps/back-office/pages/index/settings/index.vue?macro=true";
import { default as indexJKLANVOE6vMeta } from "/opt/build/repo/apps/back-office/pages/index.vue?macro=true";
import { default as loginEnHr1pJDIuMeta } from "/opt/build/repo/apps/back-office/pages/login.vue?macro=true";
import { default as logout7j2RmEAQVaMeta } from "/opt/build/repo/apps/back-office/pages/logout.vue?macro=true";
import { default as onboardingKgMjeqy0oIMeta } from "/opt/build/repo/apps/back-office/pages/onboarding.vue?macro=true";
import { default as accordionsWgX3zsk9CrMeta } from "/opt/build/repo/apps/back-office/pages/playground/accordions.vue?macro=true";
import { default as button_45copy0kv0iQOO2LMeta } from "/opt/build/repo/apps/back-office/pages/playground/button-copy.vue?macro=true";
import { default as calendar_45week1AXb3rbQi7Meta } from "/opt/build/repo/apps/back-office/pages/playground/calendar-week.vue?macro=true";
import { default as comboboxKWpLesFpSeMeta } from "/opt/build/repo/apps/back-office/pages/playground/combobox.vue?macro=true";
import { default as contracts_45apiTfzpJ5cPxcMeta } from "/opt/build/repo/apps/back-office/pages/playground/contracts-api.vue?macro=true";
import { default as errorbzWBCKPU67Meta } from "/opt/build/repo/apps/back-office/pages/playground/error.vue?macro=true";
import { default as factoivFQnKJskhMeta } from "/opt/build/repo/apps/back-office/pages/playground/facto.vue?macro=true";
import { default as google_45mapsYURfTuulCkMeta } from "/opt/build/repo/apps/back-office/pages/playground/google-maps.vue?macro=true";
import { default as indexQFybm4BYcpMeta } from "/opt/build/repo/apps/back-office/pages/playground/index.vue?macro=true";
import { default as invoice_45simulatorlweF6VaPa3Meta } from "/opt/build/repo/apps/back-office/pages/playground/invoice-simulator.vue?macro=true";
import { default as langchain_45streaming_45with_45structured_45outputMM0fDqzjxfMeta } from "/opt/build/repo/apps/back-office/pages/playground/langchain-streaming-with-structured-output.vue?macro=true";
import { default as langchain_45streaminggXnVEjCaRTMeta } from "/opt/build/repo/apps/back-office/pages/playground/langchain-streaming.vue?macro=true";
import { default as merge_45to_45pdftwRQPm5DdVMeta } from "/opt/build/repo/apps/back-office/pages/playground/merge-to-pdf.vue?macro=true";
import { default as monthFilterButtonrqCFNPVvkzMeta } from "/opt/build/repo/apps/back-office/pages/playground/monthFilterButton.vue?macro=true";
import { default as notepyW3SPLj90Meta } from "/opt/build/repo/apps/back-office/pages/playground/note.vue?macro=true";
import { default as nylas_45callbackJIOvM35BkQMeta } from "/opt/build/repo/apps/back-office/pages/playground/nylas-callback.vue?macro=true";
import { default as nylas_45companyoMqTXip4XHMeta } from "/opt/build/repo/apps/back-office/pages/playground/nylas-company.vue?macro=true";
import { default as nylas_45contactnLdo3l27owMeta } from "/opt/build/repo/apps/back-office/pages/playground/nylas-contact.vue?macro=true";
import { default as nylas_45inbox77HdcpzpqKMeta } from "/opt/build/repo/apps/back-office/pages/playground/nylas-inbox.vue?macro=true";
import { default as pappersx9j02MLuLNMeta } from "/opt/build/repo/apps/back-office/pages/playground/pappers.vue?macro=true";
import { default as posthogEWzsny8HNIMeta } from "/opt/build/repo/apps/back-office/pages/playground/posthog.vue?macro=true";
import { default as qualification_45formAROIOMDjf2Meta } from "/opt/build/repo/apps/back-office/pages/playground/qualification-form.vue?macro=true";
import { default as reporting_45barSsIZZOvZ9qMeta } from "/opt/build/repo/apps/back-office/pages/playground/reporting-bar.vue?macro=true";
import { default as sentry_45nuxtgr9SvlVQGlMeta } from "/opt/build/repo/apps/back-office/pages/playground/sentry-nuxt.vue?macro=true";
import { default as sms_45remindernIy913EufZMeta } from "/opt/build/repo/apps/back-office/pages/playground/sms-reminder.vue?macro=true";
import { default as smsKOrpEm0fVZMeta } from "/opt/build/repo/apps/back-office/pages/playground/sms.vue?macro=true";
import { default as supabase_45dropzoneyzh7dYGo65Meta } from "/opt/build/repo/apps/back-office/pages/playground/supabase-dropzone.vue?macro=true";
import { default as switch5rtPPy2do6Meta } from "/opt/build/repo/apps/back-office/pages/playground/switch.vue?macro=true";
import { default as talent_45cardswI3k60sJvMeta } from "/opt/build/repo/apps/back-office/pages/playground/talent-card.vue?macro=true";
import { default as talent_45request_45previewUTz0og2qYbMeta } from "/opt/build/repo/apps/back-office/pages/playground/talent-request-preview.vue?macro=true";
import { default as talentblRGS7XyVfMeta } from "/opt/build/repo/apps/back-office/pages/playground/talent.vue?macro=true";
import { default as team_45member_45select_45modalP4tYTCimWwMeta } from "/opt/build/repo/apps/back-office/pages/playground/team-member-select-modal.vue?macro=true";
import { default as testrQrowxuAU4Meta } from "/opt/build/repo/apps/back-office/pages/playground/test.vue?macro=true";
import { default as timesheet_45daysAayryRG3cUMeta } from "/opt/build/repo/apps/back-office/pages/playground/timesheet-days.vue?macro=true";
import { default as timesheet_45status_45pillUJzMm5HYsTMeta } from "/opt/build/repo/apps/back-office/pages/playground/timesheet-status-pill.vue?macro=true";
import { default as timesheet_45time_45formatj1LUAY2huTMeta } from "/opt/build/repo/apps/back-office/pages/playground/timesheet-time-format.vue?macro=true";
import { default as timesheet_45time_45inputEHuafrzBCBMeta } from "/opt/build/repo/apps/back-office/pages/playground/timesheet-time-input.vue?macro=true";
import { default as vue_45pdf4AuYc6Y6lZMeta } from "/opt/build/repo/apps/back-office/pages/playground/vue-pdf.vue?macro=true";
import { default as playgroundbXNKgNpkZTMeta } from "/opt/build/repo/apps/back-office/pages/playground.vue?macro=true";
export default [
  {
    name: "403",
    path: "/403",
    meta: _403WRigwub4siMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/403.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: callbackgm73ofIZLoMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/auth/callback.vue")
  },
  {
    name: "auth-confirmation",
    path: "/auth/confirmation",
    meta: confirmationAEp04yAiGJMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/auth/confirmation.vue")
  },
  {
    name: "auth-default",
    path: "/auth/default",
    meta: defaultPmi4ZoxMbZMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/auth/default.vue")
  },
  {
    name: "auth-recovery",
    path: "/auth/recovery",
    meta: recoveryN42mgWufHsMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/auth/recovery.vue")
  },
  {
    name: "customer-timesheets-signature",
    path: "/customer/timesheets/signature",
    meta: signatureCUH55DpU7DMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/customer/timesheets/signature.vue")
  },
  {
    name: indexJKLANVOE6vMeta?.name,
    path: "/",
    meta: indexJKLANVOE6vMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/index.vue"),
    children: [
  {
    name: indexDjBfQXnN9UMeta?.name,
    path: "",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index.vue"),
    children: [
  {
    name: "index-index-advance-payment-requests",
    path: "advance-payment-requests",
    meta: indexR5s8NauPU2Meta || {},
    redirect: "list",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/advance-payment-requests/index.vue"),
    children: [
  {
    name: "index-index-advance-payment-requests-index-id",
    path: ":id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/advance-payment-requests/index/[id].vue")
  },
  {
    name: "index-index-advance-payment-requests-index-list",
    path: "list",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/advance-payment-requests/index/list.vue")
  }
]
  },
  {
    name: "index-index-amendments-id",
    path: "amendments/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/amendments/[id].vue")
  },
  {
    name: "index-index-amendments",
    path: "amendments",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/amendments/index.vue")
  },
  {
    name: "index-index-applications",
    path: "applications",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/applications/index.vue")
  },
  {
    name: "index-index-calculator",
    path: "calculator",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/calculator/index.vue")
  },
  {
    name: "index-index-calculators",
    path: "calculators",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/calculators/index.vue")
  },
  {
    name: "index-index-commercial-conditions",
    path: "commercial-conditions",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/commercial-conditions/index.vue")
  },
  {
    name: "index-index-companies-id",
    path: "companies/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/companies/[id].vue")
  },
  {
    name: "index-index-company-groups-id",
    path: "company-groups/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/company-groups/[id].vue")
  },
  {
    name: "index-index-company-insurance-requests",
    path: "company-insurance-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/company-insurance-requests/index.vue")
  },
  {
    name: "index-index-contacts-id",
    path: "contacts/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/contacts/[id].vue")
  },
  {
    name: "index-index-contacts",
    path: "contacts",
    meta: indexD3JWPOqHXAMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/contacts/index.vue")
  },
  {
    name: "index-index-contracts-id",
    path: "contracts/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/contracts/[id].vue")
  },
  {
    name: "index-index-contracts",
    path: "contracts",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/contracts/index.vue")
  },
  {
    name: "index-index-convert",
    path: "convert",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/convert/index.vue")
  },
  {
    name: "index-index-cooptation",
    path: "cooptation",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/cooptation/index.vue")
  },
  {
    name: "index-index-dashboard",
    path: "dashboard",
    meta: indexkLyeEeH6RlMeta || {},
    redirect: indexkLyeEeH6RlMeta?.redirect,
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/dashboard/index.vue"),
    children: [
  {
    name: "index-index-dashboard-index-action-center",
    path: "action-center",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/dashboard/index/action-center.vue")
  },
  {
    name: "index-index-dashboard-index-statistic",
    path: "statistic",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/dashboard/index/statistic.vue")
  }
]
  },
  {
    name: "index-index-definitive-end-requests",
    path: "definitive-end-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/definitive-end-requests/index.vue")
  },
  {
    name: "index-index-expense-claim-requests-id",
    path: "expense-claim-requests/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/expense-claim-requests/[id].vue")
  },
  {
    name: "index-index-expense-claim-requests",
    path: "expense-claim-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/expense-claim-requests/index.vue")
  },
  {
    name: "index-index-independent-payment-requests-id",
    path: "independent-payment-requests/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/independent-payment-requests/[id].vue")
  },
  {
    name: "index-index-independent-payment-requests",
    path: "independent-payment-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/independent-payment-requests/index.vue")
  },
  {
    name: "index-index",
    path: "",
    meta: indexorE9GequR8Meta || {},
    redirect: "/dashboard/action-center",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/index.vue")
  },
  {
    name: "index-index-invoice-simulator",
    path: "invoice-simulator",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/invoice-simulator/index.vue")
  },
  {
    name: "index-index-meal-card-requests",
    path: "meal-card-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/meal-card-requests/index.vue")
  },
  {
    name: "index-index-medical-centers",
    path: "medical-centers",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/medical-centers/index.vue")
  },
  {
    name: "index-index-medical-checkup-requests-id",
    path: "medical-checkup-requests/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/medical-checkup-requests/[id].vue")
  },
  {
    name: "index-index-medical-checkup-requests",
    path: "medical-checkup-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/medical-checkup-requests/index.vue")
  },
  {
    name: "index-index-missions-id",
    path: "missions/:id()",
    meta: _91id_93MizrbcFo3jMeta || {},
    redirect: _91id_93MizrbcFo3jMeta?.redirect,
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/missions/[id].vue"),
    children: [
  {
    name: "index-index-missions-id-applications",
    path: "applications",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/missions/[id]/applications.vue")
  },
  {
    name: "index-index-missions-id-details",
    path: "details",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/missions/[id]/details.vue")
  },
  {
    name: "index-index-missions-id-find-talents",
    path: "find-talents",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/missions/[id]/find-talents.vue")
  },
  {
    name: "index-index-missions-id-job-posting",
    path: "job-posting",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/missions/[id]/job-posting.vue")
  },
  {
    name: "index-index-missions-id-notes",
    path: "notes",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/missions/[id]/notes.vue")
  }
]
  },
  {
    name: "index-index-missions-client-order",
    path: "missions/client-order",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/missions/client-order.vue")
  },
  {
    name: "index-index-missions",
    path: "missions",
    meta: indexFd4hBMYSgqMeta || {},
    redirect: "/missions/client-order",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/missions/index.vue")
  },
  {
    name: "index-index-missions-talent-pooling",
    path: "missions/talent-pooling",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/missions/talent-pooling.vue")
  },
  {
    name: "index-index-organigram",
    path: "organigram",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/organigram/index.vue")
  },
  {
    name: "index-index-pay-tracking-id",
    path: "pay-tracking/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/pay-tracking/[id].vue")
  },
  {
    name: "index-index-pay-tracking",
    path: "pay-tracking",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/pay-tracking/index.vue")
  },
  {
    name: "index-index-planning-contract_key",
    path: "planning/:contract_key()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/planning/[contract_key].vue")
  },
  {
    name: "index-index-planning",
    path: "planning",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/planning/index.vue")
  },
  {
    name: "index-index-ppe-requests",
    path: "ppe-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/ppe-requests/index.vue")
  },
  {
    name: "index-index-professional-card-requests-id",
    path: "professional-card-requests/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/professional-card-requests/[id].vue")
  },
  {
    name: "index-index-professional-card-requests",
    path: "professional-card-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/professional-card-requests/index.vue")
  },
  {
    name: "index-index-qualification-centers-id",
    path: "qualification-centers/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/qualification-centers/[id].vue")
  },
  {
    name: "index-index-qualification-centers",
    path: "qualification-centers",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/qualification-centers/index.vue")
  },
  {
    name: "index-index-qualification-training-requests-id",
    path: "qualification-training-requests/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/qualification-training-requests/[id].vue")
  },
  {
    name: "index-index-qualification-training-requests",
    path: "qualification-training-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/qualification-training-requests/index.vue")
  },
  {
    name: "index-index-recruitment-office-invoice-requests-id",
    path: "recruitment-office-invoice-requests/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/recruitment-office-invoice-requests/[id].vue")
  },
  {
    name: "index-index-recruitment-office-invoice-requests",
    path: "recruitment-office-invoice-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/recruitment-office-invoice-requests/index.vue")
  },
  {
    name: "index-index-reference-checks",
    path: "reference-checks",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/reference-checks/index.vue")
  },
  {
    name: "index-index-talents",
    path: "talents",
    meta: indexlPC2C1GSBmMeta || {},
    redirect: indexlPC2C1GSBmMeta?.redirect,
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index.vue"),
    children: [
  {
    name: "index-index-talents-index-id",
    path: ":id()",
    meta: _91id_93PmmYiAnQC4Meta || {},
    redirect: _91id_93PmmYiAnQC4Meta?.redirect,
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id].vue"),
    children: [
  {
    name: "index-index-talents-index-id-anonymous-bio",
    path: "anonymous-bio",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/anonymous-bio.vue")
  },
  {
    name: "index-index-talents-index-id-anonymous-resume",
    path: "anonymous-resume",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/anonymous-resume.vue")
  },
  {
    name: "index-index-talents-index-id-applications",
    path: "applications",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/applications.vue")
  },
  {
    name: "index-index-talents-index-id-contracts",
    path: "contracts",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/contracts.vue")
  },
  {
    name: "index-index-talents-index-id-details",
    path: "details",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/details.vue")
  },
  {
    name: "index-index-talents-index-id-documents",
    path: "documents",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/documents.vue")
  },
  {
    name: "index-index-talents-index-id-find-missions",
    path: "find-missions",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/find-missions.vue")
  },
  {
    name: "index-index-talents-index-id-new-page",
    path: "new-page",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/new-page.vue")
  },
  {
    name: "index-index-talents-index-id-notes",
    path: "notes",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/notes.vue")
  },
  {
    name: "index-index-talents-index-id-qualification",
    path: "qualification",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/qualification.vue")
  },
  {
    name: "index-index-talents-index-id-resume",
    path: "resume",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/[id]/resume.vue")
  }
]
  },
  {
    name: "index-index-talents-index-hiring-pools-id-details",
    path: "hiring-pools/:id()/details",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/hiring-pools/[id]/details.vue")
  },
  {
    name: "index-index-talents-index-hiring-pools",
    path: "hiring-pools",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/hiring-pools/index.vue")
  },
  {
    name: "index-index-talents-index-list",
    path: "list",
    meta: listG7Wa3iBIHDMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/list.vue")
  },
  {
    name: "index-index-talents-index-push-marketing",
    path: "push-marketing",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/push-marketing.vue")
  },
  {
    name: "index-index-talents-index-substitute-bench",
    path: "substitute-bench",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/substitute-bench.vue")
  },
  {
    name: "index-index-talents-index-super-search",
    path: "super-search",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/talents/index/super-search.vue")
  }
]
  },
  {
    name: "index-index-team-members",
    path: "team-members",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/team-members/index.vue")
  },
  {
    name: "index-index-timesheets",
    path: "timesheets",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/timesheets/index.vue")
  },
  {
    name: "index-index-users",
    path: "users",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/users/index.vue")
  },
  {
    name: "index-index-work-accident-requests-id",
    path: "work-accident-requests/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/work-accident-requests/[id].vue")
  },
  {
    name: "index-index-work-accident-requests",
    path: "work-accident-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index/work-accident-requests/index.vue")
  }
]
  },
  {
    name: "index-settings",
    path: "settings",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/settings/index.vue"),
    children: [
  {
    name: "index-settings-index-account",
    path: "account",
    meta: account968SQs7fJ6Meta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/index/settings/index/account.vue")
  },
  {
    name: "index-settings-index-members",
    path: "members",
    meta: membersWgoyzjArYfMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/index/settings/index/members.vue")
  },
  {
    name: "index-settings-index-workspace",
    path: "workspace",
    meta: workspacer5dASG9OJvMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/index/settings/index/workspace.vue")
  }
]
  }
]
  },
  {
    name: "login",
    path: "/login",
    meta: loginEnHr1pJDIuMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logout7j2RmEAQVaMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/logout.vue")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingKgMjeqy0oIMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/onboarding.vue")
  },
  {
    name: playgroundbXNKgNpkZTMeta?.name,
    path: "/playground",
    meta: playgroundbXNKgNpkZTMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/playground.vue"),
    children: [
  {
    name: "playground-accordions",
    path: "accordions",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/accordions.vue")
  },
  {
    name: "playground-button-copy",
    path: "button-copy",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/button-copy.vue")
  },
  {
    name: "playground-calendar-week",
    path: "calendar-week",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/calendar-week.vue")
  },
  {
    name: "playground-combobox",
    path: "combobox",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/combobox.vue")
  },
  {
    name: "playground-contracts-api",
    path: "contracts-api",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/contracts-api.vue")
  },
  {
    name: "playground-error",
    path: "error",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/error.vue")
  },
  {
    name: "playground-facto",
    path: "facto",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/facto.vue")
  },
  {
    name: "playground-google-maps",
    path: "google-maps",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/google-maps.vue")
  },
  {
    name: "playground",
    path: "",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/index.vue")
  },
  {
    name: "playground-invoice-simulator",
    path: "invoice-simulator",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/invoice-simulator.vue")
  },
  {
    name: "playground-langchain-streaming-with-structured-output",
    path: "langchain-streaming-with-structured-output",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/langchain-streaming-with-structured-output.vue")
  },
  {
    name: "playground-langchain-streaming",
    path: "langchain-streaming",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/langchain-streaming.vue")
  },
  {
    name: "playground-merge-to-pdf",
    path: "merge-to-pdf",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/merge-to-pdf.vue")
  },
  {
    name: "playground-monthFilterButton",
    path: "monthFilterButton",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/monthFilterButton.vue")
  },
  {
    name: "playground-note",
    path: "note",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/note.vue")
  },
  {
    name: "playground-nylas-callback",
    path: "nylas-callback",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/nylas-callback.vue")
  },
  {
    name: "playground-nylas-company",
    path: "nylas-company",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/nylas-company.vue")
  },
  {
    name: "playground-nylas-contact",
    path: "nylas-contact",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/nylas-contact.vue")
  },
  {
    name: "playground-nylas-inbox",
    path: "nylas-inbox",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/nylas-inbox.vue")
  },
  {
    name: "playground-pappers",
    path: "pappers",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/pappers.vue")
  },
  {
    name: "playground-posthog",
    path: "posthog",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/posthog.vue")
  },
  {
    name: "playground-qualification-form",
    path: "qualification-form",
    meta: qualification_45formAROIOMDjf2Meta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/qualification-form.vue")
  },
  {
    name: "playground-reporting-bar",
    path: "reporting-bar",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/reporting-bar.vue")
  },
  {
    name: "playground-sentry-nuxt",
    path: "sentry-nuxt",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/sentry-nuxt.vue")
  },
  {
    name: "playground-sms-reminder",
    path: "sms-reminder",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/sms-reminder.vue")
  },
  {
    name: "playground-sms",
    path: "sms",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/sms.vue")
  },
  {
    name: "playground-supabase-dropzone",
    path: "supabase-dropzone",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/supabase-dropzone.vue")
  },
  {
    name: "playground-switch",
    path: "switch",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/switch.vue")
  },
  {
    name: "playground-talent-card",
    path: "talent-card",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/talent-card.vue")
  },
  {
    name: "playground-talent-request-preview",
    path: "talent-request-preview",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/talent-request-preview.vue")
  },
  {
    name: "playground-talent",
    path: "talent",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/talent.vue")
  },
  {
    name: "playground-team-member-select-modal",
    path: "team-member-select-modal",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/team-member-select-modal.vue")
  },
  {
    name: "playground-test",
    path: "test",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/test.vue")
  },
  {
    name: "playground-timesheet-days",
    path: "timesheet-days",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/timesheet-days.vue")
  },
  {
    name: "playground-timesheet-status-pill",
    path: "timesheet-status-pill",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/timesheet-status-pill.vue")
  },
  {
    name: "playground-timesheet-time-format",
    path: "timesheet-time-format",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/timesheet-time-format.vue")
  },
  {
    name: "playground-timesheet-time-input",
    path: "timesheet-time-input",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/timesheet-time-input.vue")
  },
  {
    name: "playground-vue-pdf",
    path: "vue-pdf",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/vue-pdf.vue")
  }
]
  }
]