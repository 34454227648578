import { teamMemberColumns } from "@asap/shared/src/utils/supabase.columns";
import type { TeamMember } from "@asap/shared/src/utils/supabase.types";
import { useSupabase } from "~/composables/useSupabase";
import { useToast } from "~/composables/useToast";
import { useUserMe } from "~/composables/useUser";
import { useMainWorkspace } from "./useWorkspace";

export const useManagees = createSharedComposable(() => {
  const { teamMember } = useUserMe();
  const supabase = useSupabase();
  const { addToastError } = useToast();
  const { mainWorkspaceId } = useMainWorkspace();
  const managees = ref<TeamMember[]>([]);
  const directManagees = ref<TeamMember[]>([]);

  onMounted(async () => {
    if (!teamMember.value) return;
    await fetchManagees();
    await fetchDirectManagees(teamMember.value.id);
  });

  const fetchManagees = async () => {
    try {
      const { data: manageesIdsArray, error: manageesError } = await supabase.rpc("get_current_user_managees");
      if (manageesError) throw new Error(manageesError.message);
      if (!manageesIdsArray.length) return [];

      const manageesIds = manageesIdsArray.map((el) => el.managee_id);
      const { data: managedTeamMemberData, error: managedTeamMemberError } = await supabase
        .from("team_member")
        .select(teamMemberColumns)
        .in("id", manageesIds)
        .is("offboarded_at", null);

      if (managedTeamMemberError) throw new Error(managedTeamMemberError.message);
      managees.value = managedTeamMemberData;
    } catch (error) {
      addToastError(
        {
          title: "Une erreur s'est produit lors du chargement des personnes dont vous êtes le manager ou le référent",
        },
        error
      );
    }
  };

  const fetchDirectManagees = async (teamMemberId: string) => {
    try {
      const { data, error } = await supabase
        .from("team_member")
        .select(teamMemberColumns)
        .eq("manager_id", teamMemberId)
        .is("offboarded_at", null);

      if (error) throw new Error(error.message);
      directManagees.value = data;
    } catch (error) {
      addToastError(
        {
          title: "Une erreur s'est produit lors du chargement des personnes dont vous êtes le manager ou le référent",
        },
        error
      );
    }
  };

  const areSomeManageesIndependent = computed<boolean>(
    () => managees.value?.some((managee) => managee.main_workspace_id !== mainWorkspaceId.value) ?? false
  );

  const isManager = computed<boolean>(() => managees.value.length > 0);

  const isMyManagee = function (potentialManageeId: string | undefined | null) {
    if (!potentialManageeId) return false;
    if (!managees.value.length) return false;
    return managees.value.some((managee) => managee.id === potentialManageeId);
  };

  const managementLevel = computed(() => {
    if (!teamMember.value) return;
    return teamMember.value.management_level;
  });

  const isCountryManager = computed(() => {
    if (!managementLevel.value) return false;
    return managementLevel.value >= 3;
  });

  const updateManagementLevel = async (
    teamMemberId: string,
    actualManagementLevel: number,
    direction: "increase" | "decrease"
  ) => {
    const adjustment = direction === "increase" ? 1 : -1;
    const newLevel = actualManagementLevel + adjustment;

    if (newLevel < 1 || newLevel > 4) {
      throw new Error(
        `Impossible de ${direction === "increase" ? "monter au-dessus de 4" : "descendre en dessous de 1"}`
      );
    }

    const { error } = await supabase.from("team_member").update({ management_level: newLevel }).eq("id", teamMemberId);

    if (error) throw new Error(error.message);
  };

  return {
    managees,
    directManagees,
    isManager,
    isCountryManager,
    areSomeManageesIndependent,
    managementLevel,
    isMyManagee,
    updateManagementLevel,
  };
});
