import { getServerErrorMessage } from "~/utils/error";
import { logger } from "~/utils/logger";
import * as Sentry from "@sentry/nuxt";

export type Toast = {
  id?: string | number;
  title?: string;
  description?: string;
  action?: {
    btnText: string;
    altText?: string;
    trigger: () => Promise<void> | void;
  };
  duration?: number;
  type?: "success" | "danger" | "error" | "warning";
};

const id = ref(0);
const toasts = ref<Toast[]>([]);

export const useToast = function () {
  const addToast = function (notification: Toast) {
    const { type = "success", duration } = notification;
    toasts.value = [
      ...toasts.value,
      {
        id: id.value++,
        ...notification,
        type,
        duration: !duration && (type === "error" || type === "warning") ? 5000 : duration,
      },
    ];
  };

  const removeToast = function (notification: Toast) {
    toasts.value = remove(toasts.value, notification);
  };

  const addToastSuccess = (options: Toast) => {
    const { description, title, duration, action } = options;
    addToast({
      title: title || "Succès !",
      description: description || "Opération réussie.",
      type: "success",
      duration,
      action,
    });
  };

  const addToastWarning = (options: Toast) => {
    const { description, title, ...otherOptions } = options;
    addToast({
      ...otherOptions,
      title: title || "Attention !",
      description: description || "Vous êtes sur le point d'effectuer une action sensible.",
      type: "warning",
    });
  };

  const addToastError = <T>(options: Toast, error?: T) => {
    const { description, title, ...otherOptions } = options;
    if (error) logger.error(error);
    // captureError with Sentry:
    // Create a proper Error instance if we received a plain object
    const errorToCapture =
      error instanceof Error ? error : new Error(typeof error === "object" ? JSON.stringify(error) : String(error));

    // Add original error data as extra context
    if (typeof error === "object" && error !== null) {
      Sentry.withScope((scope) => {
        scope.setExtra("originalError", error);
        Sentry.captureException(errorToCapture);
      });
    } else {
      Sentry.captureException(errorToCapture);
    }

    addToast({
      ...otherOptions,
      title: title || "Oops !",
      description: description || getServerErrorMessage(error) || "Une erreur est survenue.",
      type: "error",
    });
  };

  return {
    toasts,
    addToast,
    addToastError,
    addToastWarning,
    addToastSuccess,
    remove: removeToast,
  };
};
