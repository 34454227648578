import type { Database } from "@asap/shared/src/types/supabase.gen";
type Table = keyof Database["public"]["Tables"];

const mapTableIcon: Partial<Record<Table, string>> = {
  advance_payment_request: "lucide:piggy-bank",
  company_insurance_request: "lucide:shield-check",
  independent_payment_request: "lucide:receipt-text",
  recruitment_office_invoice_request: "lucide:receipt",
  agency: "lucide:pentagon",
  application: "lucide:gallery-horizontal-end",
  acquisition: "lucide:filter",
  business_unit: "lucide:pyramid",
  company: "lucide:building",
  company_group: "lucide:building-2",
  contact: "lucide:user-circle-2",
  contract: "lucide:handshake",
  cooptation: "lucide:hand-heart",
  document: "lucide:package-open",
  expense_claim_request: "lucide:scroll-text",
  file: "lucide:file",
  job: "lucide:hard-hat",
  job_public: "lucide:hard-hat",
  medical_checkup_request: "lucide:shield-plus",
  mission: "lucide:traffic-cone",
  medical_center: "lucide:cross",
  professional_card_request: "lucide:contact-2",
  project_typology: "lucide:cuboid",
  qualification_certificate: "lucide:album",
  qualification_training_request: "lucide:badge-check",
  specialty: "lucide:atom",
  team_member: "lucide:users-2",
  talent: "lucide:bird",
  timesheet_day: "lucide:clipboard-signature",
  timesheet_week: "lucide:clipboard-signature",
  workspace: "lucide:globe",
  commercial_condition: "lucide:heart-handshake",
  notification: "lucide:bell",
  hiring_pool: "lucide:pickaxe",
} as const;

type KeysOfUnion<T> = T extends T ? keyof T : never;

type Column = KeysOfUnion<Database["public"]["Tables"][Table]["Row"]>;

const mapColumnIcon: Partial<Record<Column, string>> = {
  address: "lucide:map-pin",
  birth_date: "lucide:cake",
  coefficient: "lucide:square-asterisk",
  compensation_bonus_with_charges: "lucide:parking-meter",
  compensation_bonus_without_charges: "lucide:circle-off",
  compensation_bonus_others: "lucide:more-horizontal",
  date: "lucide:calendar",
  description: "lucide:file-signature",
  definitive_end_type: "lucide:bring-to-front",
  email: "lucide:at-sign",
  status: "lucide:circle-dot",
  is_amendment: "lucide:file-plus",
  is_definitive_end: "lucide:file-x",
  is_termination_bonus_paid_monthly: "lucide:piggy-bank",
  is_termination_bonus_to_be_paid: "lucide:piggy-bank",
  job_description: "lucide:hard-hat",
  location: "lucide:map-pin",
  mastery_level: "lucide:chart-column",
  mission_statement_description: "lucide:file-text",
  project_phase: "lucide:file-badge-2",
  nationality: "lucide:flag",
  note: "lucide:sticky-note",
  overtime: "lucide:calendar-plus",
  phone_number: "lucide:phone",
  ppe: "lucide:hard-hat",
  construction_type: "lucide:cuboid",
  sirh_name: "lucide:cable",
  real_end_date: "lucide:calendar-check",
  refund_schedule: "lucide:iteration-ccw",
  working_hours: "lucide:calendar-clock",
  job_title: "lucide:contact",
  bio: "lucide:text-quote",
  created_at: "lucide:zap",
  training_type: "lucide:library-big",
  refusal_reason: "lucide:x-circle",
  website: "lucide:panel-top",
  annual_wage: "lucide:hand-coins",
  external_interview_processes: "lucide:chevron-right-circle",
  amount: "lucide:hand-coins",
} as const;

type Enum = KeysOfUnion<Database["public"]["Enums"]>;

const mapEnumIcon: Partial<Record<Enum, string>> = {
  acquisition_source: "lucide:filter",
  app_role: "lucide:flower",
  contract_reason: "lucide:file-question",
  employee_status: "lucide:book-user",
  job_risk: "lucide:skull",
  medical_checkup_type: "lucide:stethoscope",
  job_qualification_level: "lucide:signpost",
  month: "lucide:calendar-days",
  application_stage: "lucide:gallery-horizontal-end",
} as const;

const mapUtilIcon = {
  worksite: "lucide:traffic-cone",
  arrow_left: "lucide:arrow-left",
  arrow_right: "lucide:arrow-right",
  arrow_up: "lucide:arrow-up",
  arrow_down: "lucide:arrow-down",
  badge: "lucide:user-square",
  bank_note: "lucide:banknote",
  bell: "lucide:bell",
  jobs: "lucide:concierge-bell",
  building: "lucide:building",
  camera: "lucide:camera",
  circle: "lucide:circle",
  details: "lucide:container",
  duration: "lucide:hourglass",
  delete: "lucide:trash-2",
  car: "lucide:car-front",
  calendar_check: "lucide:calendar-check",
  card: "lucide:credit-card",
  check: "lucide:check",
  check_circle: "lucide:check-circle-2",
  check_square: "lucide:check-square",
  chevron_down: "lucide:chevron-down",
  chevron_left: "lucide:chevron-left",
  chevron_right: "lucide:chevron-right",
  chevron_up: "lucide:chevron-up",
  chevron_up_down: "lucide:chevrons-up-down",
  clock: "lucide:clock-2",
  calculator: "lucide:calculator",
  comment: "lucide:pencil-line",
  construction: "lucide:construction",
  copy: "lucide:copy",
  create: "lucide:plus",
  dot_separator: "lucide:dot",
  edit: "lucide:pencil",
  expand: "lucide:maximize-2",
  question_file: "lucide:file-question",
  hat: "lucide:hard-hat",
  hashtag: "lucide:hash",
  image: "lucide:image",
  list_check: "lucide:list-checks",
  lock: "lucide:lock",
  planning: "lucide:calendar",
  private: "lucide:lock",
  public: "lucide:unlock",
  logout: "lucide:door-open",
  love: "lucide:heart",
  map: "lucide:map",
  message: "lucide:message-circle",
  message_more: "lucide:message-circle-more",
  modify: "lucide:arrow-up-down",
  money: "lucide:circle-dollar-sign",
  more_horizontal: "lucide:more-horizontal",
  more_vertical: "lucide:more-vertical",
  multidiffusion: "lucide:radio",
  notification_off: "lucide:bell-off",
  open: "lucide:arrow-up-right",
  rating_star: "lucide:star",
  close: "lucide:x",
  remove: "lucide:x",
  remove_circle: "lucide:x-circle",
  search: "lucide:search",
  smile: "lucide:smile",
  software: "lucide:laptop",
  thumbs_up: "lucide:thumbs-up",
  thumbs_down: "lucide:thumbs-down",
  tower_broadcast: "lucide:radio-tower",
  transport: "lucide:bus",
  traffic_cone: "lucide:traffic-cone",
  upload_cloud: "lucide:upload-cloud",
  user_group: "lucide:users",
  merge: "lucide:merge",
  share: "lucide:share-2",
  shortlist: "lucide:user-round-plus",
  template: "lucide:user-round-pen",
  unshortlist: "lucide:user-round-x",
  sheet: "lucide:panel-left-close",
  invite: "lucide:mail",
  smartphone: "lucide:smartphone",
  independent: "lucide:rabbit",
  table: "lucide:table",
  sun: "lucide:sun-medium",
  moon: "lucide:moon",
  long_trips: "lucide:luggage",
  anonymous_resume: "lucide:venetian-mask",
  download: "lucide:arrow-down-to-line",
  fileDownload: "lucide:file-down",
  external_link: "lucide:arrow-up-right-square",
  link: "lucide:link",
  work_accident_request: "lucide:ambulance",
  warning: "lucide:alert-triangle",
  circle_alert: "lucide:circle-alert",
  archive: "lucide:archive",
  unarchive: "lucide:package-open",
  sniper_view: "lucide:eye",
  previous: "lucide:circle-chevron-left",
  next: "lucide:circle-chevron-right",
  ai_suggestion: "lucide:sparkles",
  bot: "lucide:bot",
  fail: "lucide:circle-x",
  shared_from_external_workspace: "lucide:share-2",
  target: "lucide:target",
  job_posting: "lucide:gantt-chart-square",
  profile: "lucide:circle-user-round",
  users: "lucide:users",
  role: "lucide:key",
  ship: "lucide:ship",
  meal_voucher: "lucide:utensils",
  repeat: "lucide:repeat",
  school: "lucide:school",
  filter: "lucide:list-filter",
  dashboard_action_center: "lucide:gallery-vertical-end",
  send: "lucide:send",
  megaphone: "lucide:megaphone",
  armchair: "lucide:armchair",
  qualification: "lucide:drafting-compass",
  eye_off: "lucide:eye-off",
  network: "lucide:network",
  statistics: "lucide:chart-bar",
  undo: "lucide:undo",
  shortlisting: "lucide:user-round-plus",
  management_level: "lucide:chart-no-axes-gantt",
  call: "lucide:phone-outgoing",
  plus: "lucide:plus",
  child_arrow: "clarity:child-arrow-line",
  info: "lucide:info",
  meal_benefit: "lucide:ham",
  invoice_simulator: "lucide:file-chart-pie",
  rotate: "lucide:rotate-cw",
  rocket: "lucide:rocket",
  highway: "mdi:highway",
} as const;

export const mapIcon = {
  ...mapTableIcon,
  ...mapColumnIcon,
  ...mapEnumIcon,
  ...mapUtilIcon,
} as const as Record<string, string>;
