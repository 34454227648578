import type { WorkspaceType } from "@asap/shared/src/supabase/shared/types";
import { teamMemberColumns, workspaceColumns } from "@asap/shared/src/utils/supabase.columns";
import type { TeamMember, Workspace } from "@asap/shared/src/utils/supabase.types";
import { createSharedComposable } from "@vueuse/core";
import { useManagees } from "~/composables/useManagees";

export const useMainWorkspace = createSharedComposable(() => {
  const mainWorkspaceId = ref<string>();

  const supabase = useSupabase();

  watchEffect(async () => {
    const { data, error } = await supabase.from("workspace").select("id").eq("is_main_workspace", true).single();

    if (error) throw error;

    mainWorkspaceId.value = data.id;
  });

  return { mainWorkspaceId };
});

export const useWorkspaceMe = createSharedComposable(function () {
  const teamMembers = ref<TeamMember[]>([]);
  const currentWorkspace = ref<Workspace>();

  const supabase = useSupabase();
  const { workspaceId, isSupportRole, isDeveloper, invitedWorkspaces, isIndependent, isIndependentAdministrator } =
    useUserMe();
  const { areSomeManageesIndependent } = useManagees();
  const { mainWorkspaceId } = useMainWorkspace();

  const isAsapWorkspace = computed<boolean>(() => workspaceId.value === mainWorkspaceId.value);
  const isIndependentWorkspace = computed<boolean>(() => !isAsapWorkspace.value);

  const canSwitchWorkspace = computed<boolean>(() => {
    if (isSupportRole.value || isDeveloper.value) return true;
    if (isIndependentAdministrator.value) return true;
    if (isAsapWorkspace.value && areSomeManageesIndependent.value) return true;
    if (isIndependent.value && invitedWorkspaces.value && invitedWorkspaces.value.length > 0) return true;
    return false;
  });

  const getWorkspace = async () => {
    if (!workspaceId.value) return;

    const { data, error } = await supabase
      .from("workspace")
      .select(workspaceColumns)
      .eq("id", workspaceId.value)
      .single();

    if (error) throw error;

    currentWorkspace.value = data;
  };

  const getTeamMembers = async () => {
    if (!workspaceId.value) return;

    const { data: memberships, error: errorMemberships } = await supabase
      .from("team_member_workspace")
      .select()
      .eq("workspace_id", workspaceId.value);
    if (errorMemberships) throw errorMemberships;

    const { data, error } = await supabase
      .from("team_member")
      .select(teamMemberColumns)
      .in(
        "id",
        memberships.map((membership) => membership.team_member_id)
      );
    if (error) throw error;

    teamMembers.value = data;
  };

  watchEffect(async () => {
    await getWorkspace();
    await getTeamMembers();
  });

  return {
    currentWorkspace,
    teamMembers,
    isAsapWorkspace,
    isIndependentWorkspace,
    canSwitchWorkspace,
  };
});

export const useWorkspaceFilter = createSharedComposable(() => {
  const workspaceType = ref<WorkspaceType>("internal");

  return {
    workspaceType,
  };
});
