import type { AppRole } from "../types/supabase.types.gen";

const IMAGE_MIMETYPES = ["image/jpeg", "image/png"] as const;
export const PDF_MIMETYPES = ["application/pdf"] as const;
export const DOCUMENT_MIMETYPES = [...PDF_MIMETYPES, ...IMAGE_MIMETYPES] as const;
export const MICROSOFT_OFFICE_MIMETYPES = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
] as const;
export const HEIC_MIMETYPES = ["image/heic", "image/heif"] as const;

export const BUSINESS_UNITS = [
  "CET",
  "TP / VRD",
  "Bâtiment & Gros oeuvre",
  "Télécom et énergies",
  "Constructions modulaires",
  "Management de projets",
  "Clos et couvert",
  "Bureau d'études",
  "Tertiaire",
  "Enveloppe du bâtiment",
] as const;

export const BOARD_MANAGERS_EMAILS = [
  "baptiste@asap.work",
  "emilie.vialle@asap.work",
  "mathias@asap.work",
  "steve@asap.work",
  "jimmy@asap.work",
  "julien.gaultier@asap.work",
  "francois.gouzon@asap.work",
  "florent.pasquiou@asap.work",
  "guenael@asap.work",
] as const;

export const METABASE_DASHBOARD_KEYS = [
  "prodBoardManager",
  "prodConsultant",
  "prodConsultantWithManagedTeamMembers",
  "mapIndependentTeamMemberManager",
] as const;

export const CONTRACT_MINIMUM_AMOUNT = 10000;
export const ASAP_TT_COMPANY_ID = "4a5eb0aa-e92f-4b8b-8f8f-f2cdbd4337f9";

export const SUPPORT_ROLES: AppRole[] = [
  "team_member_independent_administrator",
  "csm_team_member",
  "accounting_team_member",
  "team_member_marketing",
] as const;

export enum WORKSPACE_TYPES {
  INTERNAL = "internal",
  EXTERNAL = "external",
}

export const DEFAULT_QUERY_LIMIT = 50;
export const SMALL_QUERY_LIMIT = 20;
