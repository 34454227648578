/**
 * We use this logger for Frontend
 */

interface Logger {
  debug: (message?: any, ...optionalParams: any[]) => void;
  log: (message?: any, ...optionalParams: any[]) => void;
  info: (message?: any, ...optionalParams: any[]) => void;
  warn: (message?: any, ...optionalParams: any[]) => void;
  error: (message?: any, ...optionalParams: any[]) => void;
}

class ConsoleLogger implements Logger {
  debug = console.debug;
  log = console.log;
  info = console.info;
  warn = console.warn;
  error = console.error;
}

export const logger: Logger = new ConsoleLogger();
