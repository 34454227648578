export const isAbortError = (error: any) => {
  return typeof error === "object" && !!error && "message" in error && error.message.toLowerCase().includes("aborted");
};

export const getErrorMessage = function (error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
};

export const getServerErrorMessage = function (error: any) {
  if (error?.data?.message) return error?.data?.message;
  return getErrorMessage(error);
};
