/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */

export const baseFileColumns = `*` as const;

export const baseCompanyColumns = `
  id,
  name,
  siren,
  company_group_id,
  created_at,
  created_by,
  formatted_address,
  naf_code_label,
  updated_at,
  updated_by,
  status
` as const;

export const baseAgencyColumns = `
  id,
  name,
  siret,
  naf_code_label,
  is_head_office,
  company_id,
  address_id
` as const;

export const baseJobColumns = `
  id,
  name,
  workspace_id
` as const;

export const baseTalentSoftwareColumns = `*` as const;

export const baseBusinessUnitColumns = `*` as const;

export const baseTeamMemberColumns = `*` as const;

export const baseWorkspaceColumns = `
  id,
  created_at,
  name,
  data_transferred_at,
  data_transferred_to,
  data_transferred_by
` as const;

export const baseTeamMember = `*` as const;

export const baseContact = `*` as const;

export const baseTalentJobExperienceColumns = `*` as const;

export const baseResumeColumns = `
  id,
  talent_id,
  is_anonymized,
  created_at
` as const;

export const baseTalentSearchColumns = `
  id,
  first_name,
  last_name,
  email,
  phone_number,
  birth_date,
  emergency_contact,
  location,
  nationality,
  gender,
  picture_id,
  job_name
` as const;

export const baseReferenceCheckColumns = `
  id,
  created_at,
  updated_at,
  status,
  note,
  reminder_date,
  contact_id
` as const;

export const baseHiringPoolColumns = `*` as const;

export const baseTalentColumns = `
  id,
  created_at,
  first_name,
  last_name,
  email,
  phone_number,
  annual_wage,
  availability_date,
  hourly_wage,
  has_driver_license,
  contract_types,
  construction_types,
  created_by,
  updated_at,
  updated_by,
  temp_created_by_email,
  temp_updated_by_email,
  experience_level,
  birth_date,
  has_vehicle,
  travel_preferences,
  project_phases,
  availability_note,
  has_managerial_experience,
  size_of_construction_site,
  emergency_contact,
  nationality,
  gender,
  picture_id,
  salary_expectation,
  anonymous_resume_file_id,
  bio,
  is_opened_to_business_trips,
  long_business_trips_extra_info,
  job_extra_info,
  is_top_profile,
  qualification_status,
  has_auth_access,
  country_of_birth,
  city_of_birth,
  residency_permit_number,
  professional_card_number,
  job_name,
  external_interview_processes,
  availability_status,
  availability_status_updated_at,
  availability_status_updated_by,
  number_of_people_managed,
  owner_id,
  is_benched,
  search_full_name,
  job_name_embedding,
  has_no_valid_resume,
  profile_summary_embedding,
  is_foreign_candidate,
  resume_parsing_status,
  resume_parsing_started_at,
  data_extraction_status,
  data_extraction_started_at,
  profile_summary_status,
  profile_summary_started_at,
  profile_summary_status,
  address_id,
  blacklisted_at,
  blacklisted_by,
  blacklist_reason,
  pre_qualified_at,
  qualification_started_at,
  qualification_completed_at
` as const;

export const baseContractColumns = `
  id,
  created_at,
  created_by,
  mission_id,
  talent_id,
  start_date,
  end_date,
  hourly_wage,
  coefficient,
  employee_status,
  sirh_name,
  overtime,
  compensation_bonus_with_charges,
  compensation_bonus_without_charges,
  compensation_bonus_others,
  ppe_comments,
  job_risks,
  risk_others,
  send_timesheet_first_to_client,
  working_days,
  is_sirh_request,
  is_termination_bonus_paid_monthly,
  working_hours,
  job_id,
  job_description,
  mission_statement_description,
  contract_reason,
  contract_key,
  amendment_type,
  is_amendment,
  status,
  agency_id,
  workspace_id,
  timesheet_launch_date,
  definitive_end_type,
  definitive_end_details,
  real_end_date,
  is_definitive_end,
  is_termination_bonus_to_be_paid,
  definitive_end_processed_at,
  is_definitive_end_processed,
  owner_id,
  admin_id,
  previous_contract_key,
  amendment_comment,
  updated_at,
  updated_by,
  is_talent_onboarding_needed,
  definitive_end_created_at,
  job_name,
  is_pre_employment_declaration_needed,
  is_signature_on_worksite_possible,
  should_fill_timesheets_on_app,
  ppe,
  meal_type,
  meal_card_value,
  address_id
` as const;

export const baseContractForTableColumns = `
  id,
  start_date,
  end_date,
  status,
  created_at,
  amendment_type,
  contract_key,
  hourly_wage,
  coefficient,
  is_definitive_end,
  real_end_date,
  previous_contract_key,
  previous_contract_key,
  definitive_end_type,
  is_signature_on_worksite_possible,
  meal_card_value,
  meal_type
` as const;

export const baseMissionColumns = `*` as const;

export const baseApplicationColumns = `*` as const;

export const baseQualificationCertificateColumns = `*` as const;

export const baseQualificationTrainingRequestColumns = `*` as const;

export const baseQualificationCenterColumns = `
  id,
  name,
  address,
  location,
  email,
  phone_number,
  website,
  status,
  training_type
` as const;

export const baseQualificationCenterForTableColumns = `
  id,
  name,
  address,
  location,
  email,
  phone_number,
  website,
  status
` as const;

export const baseIndependentPaymentRequestColumns = `
  id,
  month,
  amount,
  comment,
  status
` as const;

export const baseDocumentColumns = `
  id,
  type,
  expiry_date,
  docuseal_submission_url,
  docuseal_submission_id,
  docuseal_template_id
` as const;

export const baseRecruitmentOfficeInvoiceRequestForTableColumns = `
  id,
  status,
  annual_wage,
  job_name
` as const;

export const baseRecruitmentOfficeInvoiceRequestColumns = `
  id,
  status,
  annual_wage,
  pricing,
  start_date,
  comment,
  timeline,
  job_name
` as const;

export const baseRecruitmentOfficePaymentColumns = "*" as const;

export const baseMedicalCheckupRequestColumns = `*` as const;

export const baseMedicalCheckupRequestForTableColumns = `
  id,
  status,
  created_at,
  workspace_id,
  scheduled_at,
  near_to,
  medical_center_id,
  medical_checkup_type
` as const;

export const baseMedicalCenterColumns = `
  id,
  name,
  address,
  location,
  email,
  phone_number,
  website,
  status
` as const;

export const baseProfessionalCardRequestColumns = `*` as const;

export const baseExpenseClaimRequestColumns = `*` as const;

export const baseAdvancePaymentRequestColumns = `*` as const;

export const basePpeRequestColumns = `*` as const;

export const basePpeColumns = `*` as const;

export const baseProjectTypologyColumns = `*` as const;

export const baseSpecialtyColumns = `*` as const;

export const baseNoteColumns = `*` as const;

export const baseNotificationColumns = `
  id,
  created_at,
  recipient_roles,
  data,
  is_read,
  type
` as const;

export const baseContractForTimesheetColumns = `
  job_name
` as const;

export const baseContractForTimesheetLinkColumns = `*` as const;

export const baseContractForSelectTimesheetColumns = `
  id,
  contract_key,
  start_date,
  end_date,
  real_end_date,
  timesheet_launch_date,
  amendment_type,
  working_days
` as const;

export const baseTimesheetSignerColumns = `
  is_worksite_signer
` as const;

export const baseCommercialConditionColumns = `*` as const;

export const baseTimesheetWeekColumns = `
  status,
  contract_key,
  date
` as const;

export const baseCompanyInsuranceRequestColumns = `*` as const;

export const baseViewContractColumns = `*` as const;

export const baseUserForTableColumns = `
  id,
  email,
  first_name,
  last_name
` as const;

export const baseCooptationForTableColumns = `*` as const;

export const baseTalentProjectTypologyColumns = `*` as const;

export const baseSmsColumns = `
  talent_id,
  send_at,
  content,
  is_sent
` as const;

export const baseContactColumns = `*` as const;

export const baseTalentJobColumns = `*` as const;

export const baseAcquisitionColumns = `*` as const;

export const baseMissionWorkspaceColumns = `*` as const;

export const baseEmailMessageColumns = `*` as const;

export const baseRegionColumns = `code, name, local_code` as const;

export const baseDepartmentColumns = `code, name, local_code, region_code` as const;

export const baseTalentPushMarketingRequestColumns = `*` as const;

export const baseAddressTableColumns = `
  id,
  formatted,
  latitude,
  longitude,
  department_code,
  postal_code,
  street1,
  street2,
  city,
  department_name,
  region_name,
  extra_info,
  type
` as const;

export const baseContractContactColumns = `*` as const;

export const baseMealCardRequestColumns = `*` as const;
